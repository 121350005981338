import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { LandingContentProvider } from './context/LandingContentContext';
import Login from './pages/Login';
import Register from './pages/Register';
import Chat from './pages/Chat';
import LandingPage from './pages/LandingPage';
import SupportContent from './context/SupportContent';

function PrivateRoute({ element, ...rest }) {
    const { user } = useContext(AuthContext);
    return user ? element : <Navigate to="/" />;
}

function PublicRoute({ element, ...rest }) {
    const { user } = useContext(AuthContext);
    return user ? <Navigate to="/app" /> : element;
}

function AppRouter() {
    return (
        <AuthProvider>
            <LandingContentProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<PublicRoute element={<LandingPage />} />} />
                        <Route path="/login" element={<PublicRoute element={<Login />} />} />
                        <Route path="/register" element={<PublicRoute element={<Register />} />} />
                        <Route path="/chat" element={<PrivateRoute element={<Chat />} />} />
                    </Routes>
                </Router>
                <SupportContent />
            </LandingContentProvider>
        </AuthProvider>
    );
}

export default AppRouter;