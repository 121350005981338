import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLandingContent } from '../context/LandingContentContext';

function LandingPage() {
    const { content } = useLandingContent();
    const [currentImage, setCurrentImage] = useState('/overview.png');

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => 
                prevImage === '/overview.png' ? '/chat_interface.png' : '/overview.png'
            );
        }, 5000); // Switch every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h2" component="h1" gutterBottom>
                    {content.title}
                </Typography>
                <Typography variant="h5" component="p" gutterBottom>
                    {content.description}
                </Typography>
                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item>
                        <Button variant="contained" component={Link} to="/login">
                            Sign In
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" component={Link} to="/register">
                            Register
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4, height: 400, overflow: 'hidden' }}>
                    <Box
                        component="img"
                        src={currentImage}
                        alt="Job Fit Evaluation"
                        sx={{
                            maxWidth: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'opacity 0.5s ease-in-out',
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
}

export default LandingPage;