import React from 'react';
import { Box, Typography } from '@mui/material';

const SupportContent = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="body2">
        Contact us: support@recript.com
      </Typography>
    </Box>
  );
};

export default SupportContent;