import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Typography, TextField, Paper, Grid } from '@mui/material';
import AuthContext from '../context/AuthContext';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

function Chat() {
    const { user } = useContext(AuthContext);
    const [resume, setResume] = useState(null);
    const [jobDescription, setJobDescription] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [chatHistory, setChatHistory] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [currentResumeId, setCurrentResumeId] = useState('');
    const [stage, setStage] = useState('upload');
    
    useEffect(() => {
        validateForm(resume, jobDescription);
    }, [resume, jobDescription]);

    const validateForm = (resumeFile, jobDesc) => {
        setIsSubmitDisabled(!(resumeFile && jobDesc && jobDesc.length <= 10000));
    };

    const handleResumeChange = (e) => {
        setResume(e.target.files[0]);
    };

    const handleJobDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('resume', resume);
        formData.append('jobDescription', jobDescription);

        try {
            const uploadResponse = await axios.post('/api/resumes/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${user.token}`,
                },
            });
            if (uploadResponse.status === 201) {
                const chatResponse = await axios.post('/api/chat/initialize', {
                    resumeId: uploadResponse.data.resume._id,
                    jobDescription: jobDescription
                }, {
                    headers: {
                        Authorization: `${user.token}`,
                    },
                });
                setCurrentResumeId(uploadResponse.data.resume._id);
                setChatHistory([{ sender: 'bot', message: chatResponse.data.message }]);
                setStage('chat');
            }
        } catch (error) {
            console.error('Error uploading resume and initializing chat:', error);
        }
    };

    const handleUserInput = async () => {
        if (userInput.trim()) {
            const updatedHistory = [...chatHistory, { sender: 'user', message: userInput }];
            setChatHistory(updatedHistory);
            try {
                const response = await axios.post('/api/chat/interact', 
                    { 
                        message: userInput,
                        resumeId: currentResumeId
                    }, 
                    {
                        headers: {
                            Authorization: `${user.token}`,
                        },
                    }
                );
                const botResponse = { sender: 'bot', message: response.data.message };
                setChatHistory(prev => [...prev, botResponse]);
            } catch (error) {
                console.error('Error in chat interaction:', error);
            }
            setUserInput('');
        }
    };

    const handleReset = () => {
        setResume(null);
        setJobDescription('');
        setChatHistory([]);
        setCurrentResumeId('')
        setStage('upload');
    };

    const renderChatMessage = (message, sender) => (
        <Box sx={{ mb: 2, textAlign: sender === 'user' ? 'right' : 'left' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {sender === 'user' ? 'You:' : 'Bot:'}
            </Typography>
            <Paper 
                elevation={1} 
                sx={{ 
                    p: 2, 
                    maxWidth: '80%', 
                    display: 'inline-block',
                    textAlign: 'left'
                }}
            >
                <ReactMarkdown>{message}</ReactMarkdown>
            </Paper>
        </Box>
    );

    
    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Hello {user.user}
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleReset} sx={{ mb: 2 }}>
                Reset
            </Button>
            {stage === 'upload' ? (
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<UploadFileIcon />}
                            >
                                Upload Resume
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleResumeChange}
                                />
                            </Button>
                            {resume && <Typography variant="body2">{resume.name}</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                label="Enter job description here"
                                value={jobDescription}
                                onChange={handleJobDescriptionChange}
                                error={jobDescription.length > 10000}
                                helperText={`${jobDescription.length}/10000`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isSubmitDisabled}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Paper elevation={3} sx={{ p: 2, maxHeight: '60vh', overflow: 'auto' }}>
                    {chatHistory.map((chat, index) => (
                        <Box key={index}>
                            {renderChatMessage(chat.message, chat.sender)}
                        </Box>
                    ))}
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Your message"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleUserInput()}
                        />
                        <Button variant="contained" color="primary" onClick={handleUserInput} sx={{ mt: 1 }}>
                            Send
                        </Button>
                    </Box>
                </Paper>
            )}
        </Box>
    );
}

export default Chat;