import React, { createContext, useState, useContext } from 'react';

const LandingContentContext = createContext();

export const useLandingContent = () => useContext(LandingContentContext);

export const LandingContentProvider = ({ children }) => {
    const [content, setContent] = useState({
        title: 'Evaluate your fit for jobs and apply more confidently!',
        description: 'Have your own job search assistant, providing personalized feedback and suggestions to improve your chances of landing your dream job.'
    });

    const updateContent = (newContent) => {
        setContent(prevContent => ({ ...prevContent, ...newContent }));
    };

    return (
        <LandingContentContext.Provider value={{ content, updateContent }}>
            {children}
        </LandingContentContext.Provider>
    );
};